<template>
    <div>
        <Navigation />
        <div class="panel">
            <h1>Moderator board</h1>
        </div>
    </div>
</template>


<script>
import Navigation from '@/components/V1/Navigation.vue'

export default {
    name: "Moderator",
    components: {
        Navigation
    }
} 
</script>

<style scoped>
</style>